<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <ErrorFormField :msgArray="commonErrors" :msg-type="$data.result.status" />
        <div class="input-group mb-3">
          <span class="input-group-text" id="loginIconEmail"
            ><i class="far fa-envelope input-icon-swing"></i
          ></span>
          <input type="email" id="loginEmail" class="form-control" placeholder="Email Address" />
        </div>
        <ErrorFormField :msgArray="emailErrors" v-if="emailErrors.length > 0" />
        <div class="input-group mb-3">
          <span class="input-group-text" id="loginIconPassword"
            ><i class="fa fa-lock input-icon-swing"></i
          ></span>
          <input type="password" class="form-control" id="loginPassword" placeholder="Password" />
        </div>
        <ErrorFormField :msgArray="passwordErrors" v-if="passwordErrors.length > 0" />
        <div class="mb-3 d-flex">
          <div class="text-end flex-fill">
            <router-link :to="{ name: 'ForgotPassword' }" class="text-end">
              Forgot password?</router-link
            >
          </div>
        </div>
        <div class="mb-3">
          <LoadingIcons v-if="ajaxLoad.normal" />
          <button
            class="btn btn-primary w-100"
            id="btnLoginModal"
            @click="btnLoginClick"
            type="submit"
            :disabled="ajaxLoad.normal"
            v-else
          >
            Log in
          </button>
        </div>

        <!--                    </form>-->
        <div class="social-buttons d-grid gap-2 mb-3">
          <a class="btn btn-social btn-facebook" @click="btnfacebookHandle">
            <span class="fab fa-facebook 123"> </span> Log In with Facebook
          </a>
          <a class="btn btn-social btn-google" @click="btnGoogleHandle">
            <span class="fab fa-google-plus"> </span> Log In with Google
          </a>
          <!--                          data-accesstype="offline"-->
          <!--              data-scope="https://www.googleapis.com/auth/plus.login"-->
          <!--              :data-redirecturi="currentUrl"-->
          <!--            <div class="g-signin2 d-inline-block" data-onsuccess="onSignIn"></div>-->
          <router-link :to="{ name: 'SignUp' }" class="btn btn-warning"> Sign up</router-link>
        </div>
        <div class="g-signin2" data-onsuccess="onSignIn"></div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingIcons from '../../common/LoadingIcons.vue';
import ErrorFormField from '../../common/error/ErrorFormField.vue';
//eslint-disable-next-line
import Cookies from 'js-cookie';
// let bootstrap = require('bootstrap');

export default {
  name: 'Login',
  components: { ErrorFormField, LoadingIcons },
  data: function () {
    return {
      commonErrors: [],
      emailErrors: [],
      passwordErrors: [],
      // formData : {email: "", password: ""},
      ajaxLoad: {
        normal: false,
        facebook: false,
        google: false,
      },
      result: {
        status: '',
      },
    };
  },
  methods: {
    btnLoginClick: async function () {
      this.$data.ajaxLoad.normal = true;
      let apiUrl = process.env.VUE_APP_API_URL;
      let data1 = {
        email: document.getElementById('loginEmail').value,
        password: document.getElementById('loginPassword').value,
      };

      const formData = Object.keys(data1)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data1[key]))
        .join('&');
      let self = this;
      await fetch(apiUrl + 'login', {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((resData) => {
          let messages = resData.messages;
          if (resData.status == 'error') {
            self.$data.result.status = 'error';
            if (messages.common.length > 0) self.$data.commonErrors = messages.common;
            else self.$data.commonErrors = [];

            if (messages.email.length > 0) self.$data.emailErrors = messages.email;
            else self.$data.emailErrors = [];

            if (messages.password.length > 0) self.$data.passwordErrors = messages.password;
            else self.$data.passwordErrors.length = 0;
            console.log('self.$data ', self.$data);
            return false;
          }
          if (resData.status == 'warning') {
            self.$data.result.status = 'warning';
            self.$data.commonErrors = messages.common;
            return false;
          }

          Cookies.set('name', resData.name);
          Cookies.set('image', resData.image);
          Cookies.set('token', resData.token);
          Cookies.set('role', resData.role);
          Cookies.set('totalNotifications', resData.totalNotifications);
          sessionStorage.setItem('notifications', JSON.stringify(resData.notifications));
          Cookies.set('currentRole', 'seeker');
          Cookies.set('active', resData.active);
          Cookies.set('email_verify', resData.email_verify);
          Cookies.set('phone_verify', resData.phone_verify);
          Cookies.set('unique_id', resData.unique_id);
          self.$store.commit('loginUpdate', resData);

          // self.$store.dispatch('loginUpdate', resData)
          console.log('success login');
          if (resData.active == 1) self.$router.push('/');
          else {
            if (resData.phone_verify == 0) {
              self.$router.push('/phone-verify');
              return;
            }
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      this.$data.ajaxLoad.normal = false;
    },

    btnGoogleHandle: async function () {
      this.$data.ajaxLoad.google = true;
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
        console.log('id_token', googleUser.getAuthResponse().id_token);
        let token = googleUser.getAuthResponse().id_token;
        let apiUrl = process.env.VUE_APP_API_URL;
        let data1 = {
          token: token,
        };

        const formData = Object.keys(data1)
          .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data1[key]))
          .join('&');
        let self = this;
        await fetch(apiUrl + 'google-login', {
          method: 'POST',
          headers: {
            // 'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
          },
          body: formData,
        })
          .then((response) => response.json())
          .then((resData) => {
            console.log('Success:', resData);
            let messages = resData.messages;
            if (resData.status == 'error') {
              if (messages.common.length > 0) self.$data.commonErrors = messages.common;
              else self.$data.commonErrors = [];

              console.log('self.$data ', self.$data);
              return false;
            }

            Cookies.set('name', resData.name);
            Cookies.set('image', resData.image);
            Cookies.set('token', resData.token);
            Cookies.set('role', resData.role);
            Cookies.set('totalNotifications', resData.totalNotifications);
            sessionStorage.setItem('notifications', JSON.stringify(resData.notifications));
            Cookies.set('currentRole', 'seeker');
            Cookies.set('active', resData.active);
            Cookies.set('email_verify', resData.email_verify);
            Cookies.set('phone_verify', resData.phone_verify);
            Cookies.set('unique_id', resData.unique_id);
            self.$store.commit('loginUpdate', resData);
            console.log('success login');

            if (resData.active == 1) self.$router.push('/');
            else {
              if (resData.phone_verify == 0) {
                self.$router.push('/phone-verify');
                return;
              }
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
        this.$data.ajaxLoad.google = false;
      } catch (error) {
        //on fail do something
        console.error(error);
        return null;
      }
    },
    btnfacebookHandle: async function () {
      // FB.login(async function (response) {
      let self = this;
      //eslint-disable-next-line
      FB.login(async function (response) {
        console.log(response);
        if (response.status === 'connected') {
          // Logged into your webpage and Facebook.
          console.log('connected ', response);

          self.$data.ajaxLoad.facebook = true;
          try {
            let token = response.authResponse.accessToken;
            let apiUrl = process.env.VUE_APP_API_URL;
            let data1 = {
              token: token,
            };

            const formData = Object.keys(data1)
              .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data1[key]))
              .join('&');
            // let self = this;
            await fetch(apiUrl + 'facebook-login', {
              method: 'POST',
              headers: {
                // 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
              },
              body: formData,
            })
              .then((response) => response.json())
              .then((resData) => {
                console.log('Success:', resData);
                let messages = resData.messages;
                if (resData.status == 'error') {
                  if (messages.common.length > 0) self.$data.commonErrors = messages.common;
                  else self.$data.commonErrors = [];

                  console.log('self.$data ', self.$data);
                  return false;
                }
                Cookies.set('name', resData.name);
                Cookies.set('image', resData.image);
                Cookies.set('token', resData.token);
                Cookies.set('role', resData.role);
                Cookies.set('totalNotifications', resData.totalNotifications);
                sessionStorage.setItem('notifications', JSON.stringify(resData.notifications));
                Cookies.set('currentRole', 'seeker');
                Cookies.set('active', resData.active);
                Cookies.set('email_verify', resData.email_verify);
                Cookies.set('phone_verify', resData.phone_verify);
                Cookies.set('unique_id', resData.unique_id);
                self.$store.commit('loginUpdate', resData);
                console.log('success login');

                if (resData.active == 1) self.$router.push('/');
                else {
                  if (resData.phone_verify == 0) {
                    self.$router.push('/phone-verify');
                    return;
                  }
                }
              })
              .catch((error) => {
                console.error('Error:', error);
              });
            self.$data.ajaxLoad.facebook = false;
          } catch (error) {
            //on fail do something
            console.error(error);
            return null;
          }
        } else {
          // The person is not logged into your webpage or we are unable to tell.
          console.log('not login ... ', response);
        }
      });
    },
  },
  computed: {
    currentUrl: function () {
      return window.location.origin + this.$route.fullPath;
    },
  },
  mounted() {
    document.getElementById('loginEmail').value = '';
    document.getElementById('loginPassword').value = '';
  },
};
</script>

<style scoped></style>
